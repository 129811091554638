<template>
  <div class="agent-verify">
    <heads title="代理人验证"></heads>
    <div class="finish-content">
      <van-field v-model="name" placeholder="请输入真实姓名" />
      <van-field v-model="idCard" placeholder="请输入身份证号码" />
      <van-button :class="`${name&&idCard?'sure-btn ':'sure-btn btn-opacity'}`" @click="save">确认</van-button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
export default {
  components: {
    heads: Header,
  },
  data() {
    return {
      name: null,
      idCard: null,
    }
  },
  methods: {
    save(){
      if(!this.name){
        this.$toast('请输入姓名');
        return;
      }
      if(!this.idCard){
        this.$toast('请输入身份证号码');
        return;
      }
      this.$router.back();
    }
  },
}
</script>

<style lang="scss" scoped>
.agent-verify{
}
</style>
